<template>
  <v-container class="pa-4" fluid>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <!-- <v-responsive :aspect-ratio="16/9"> -->
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <div style="position: relative">
            <!-- <v-skeleton-loader
                v-show="!bookingDetails.hasOwnProperty('zone')"
                height="260px"
                class="rounded-t-lg"
                type="image"
              ></v-skeleton-loader>
               -->
            <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(50%)" :src="
              zoneDetails.hasOwnProperty('image') &&
                zoneDetails.image != '' &&
                zoneDetails.image != null
                ? zoneDetails.image
                : zoneImgURL
            " :lazy-src="
  zoneDetails.hasOwnProperty('image') &&
    zoneDetails.image != '' &&
    zoneDetails.image != null
    ? zoneDetails.image
    : zoneImgURL
" alt="Parking.com garage">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-row>
              <div class="thankyou_header white--text text-left mr-4">
                THANK YOU!
              </div>
            </v-row>
          </div>
          <v-card-title class="pa-1"></v-card-title>
          <v-card-text class="pa-2">
            <v-row align="center" class="mx-0 pb-2" no-gutters> </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Name :</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{ reservationData.name }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Email :</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{ reservationData.email }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Mobile No:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{ reservationData.contact }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Plate:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{ reservationData.vehicleNo || "-" }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Valid From:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{ formatDate(reservationData.startAt) }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header">Valid until:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date">
                  {{ formatDate(reservationData.stopAt) }}
                </p>
              </v-col>
            </v-row>
            <v-row class="justify-center mb-1" no-gutters>
              <span class="black--text text--darken-1 font-weight-bold pa-1">Parking pass successfully created
              </span>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mt-2 text-center color-black px-2" style="font-size: 12px">
                <p class="mb-0">
                  Please click the button below to view your parking pass.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center pb-10">
            <v-container class="pa-0" fluid>
              <v-row no-gutters>
                <v-col cols="12" class="px-2">
                  <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                    @click="navigateToHostPass">Parking Pass</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
export default {
  name: "CheckIn",
  components: {},
  data: () => ({ routeData: {}, startAt: "" }),
  computed: {
    ...mapGetters({
      zoneDetails: "getterZoneDetails",
      reservationData: "getterHostPassData",
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
    }),
  },
  watch: {},
  created() { },
  mounted() {
    this.startAt = this.reservationData;
  },
  destroyed() { },

  // beforeRouteEnter(to, from, next) {
  //   next(async (vm) => {
  // },
  methods: {
    navigateToHostPass() {
      //url segment is app mixin computed property
      let url = window.location.origin + '/g/' + this.reservationData.refno;
      window.location.replace(url);
    },
    formatDate(reservationDate) {
      return format(new Date(reservationDate), "LLLL do yyyy, hh:mm a")
    },
  },
};
</script>
<style lang="less">
.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}
.booking_header {
  position: absolute;
  bottom: 60px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}
.booking_divider {
  border-color: white !important;
  margin-top: 12px;
}
.booking_divider_div {
  position: absolute;
  bottom: 45px;
  width: 100%;
}
.booking_date {
  font-weight: normal;
  font-size: 13px;
  color: #3d3f3c !important;
}
.booking_date_header {
  color: #3d3f3c !important;
  font-weight: bold;
  font-size: 14px;
}
.booking_content {
  position: absolute;
  bottom: 18px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}
.thankyou_header {
  position: absolute;
  bottom: 20px;
  left: 16px;
  font-size: 24px;
  font-weight: bolder;
}
</style>
